import React from 'react'
import css from './testimonials.module.css'
import Hero from '../../assets/testimonialHero.png'
import {TestimonialsData} from '../../data/testimonials'
import { Swiper,SwiperSlide } from 'swiper/react'
const Testimonials = () => {
  return (
    
      <div className={css.Testimonials}>
        <div className={css.wrapper}>
          <div className={css.container}>
            <span>Top Rated </span>
            <span>Seedily say has suitable disposal and boy.Exercise joy man children Rejoiced</span>
          </div>
          <img src={Hero} alt="" />
          <div className={css.container}>
            <span>100K</span>
            <span>Happy Customer With Us</span>
          </div>
        </div>
        <div className={css.review}>Reviews</div>
        <div className={css.carousel}>
        <Swiper
  slidesPerView={3}
  slidesPerGroup={1}
  spaceBetween={20}
  className={css.tcarousel}
>
  {TestimonialsData.map((testimonial, i) => (
    <SwiperSlide key={i}>
      <div className={css.testimonial}>
        <img src={testimonial.image} alt="" />
        <span>{testimonial.comment}</span>
        <hr />
        <span>{testimonial.name}</span>
      </div>
    </SwiperSlide>
  ))}
</Swiper>
        </div>
      </div>
  )
}

export default Testimonials
