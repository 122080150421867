import "./App.css";
import Header from "./components/header/header";
import Hero from './components/Hero/hero';
import Product from "./components/product/product";
import Slider from "./components/Slider/slider";
import Testimonials from "./components/testimonials/testimonials";
import Virtual from "./components/virtual/virtual";
function App() {
  return (
    <div className="App">
<Header/>
<Hero/>
<Slider/>
<Virtual/>
<Product/>
<Testimonials/>
    </div>
  );
}

export default App;
