import React, { useState } from 'react'
import css from './product.module.css'
import Plane from '../../assets/plane.png'
import { ProductsData } from '../../data/products'
import { useAutoAnimate } from '@formkit/auto-animate/react'
const Product = () => {
const parent = useAutoAnimate()

    const [MenuProducts, SetMenuProducts] = useState(ProductsData);
    const filter= (type)=>{
        SetMenuProducts(ProductsData.filter((product)=>product.type===type))
    }
    return (
        <div>
            <div className={css.container}>
                <img src={Plane} alt="" />
                <h1>Our Featured Products</h1>
                <div className={css.products}>
                    <ul className={css.menu} >
                        <li onClick={()=>SetMenuProducts(ProductsData)}>All</li>
                        <li onClick={()=>filter("skin care")}>Skin Care</li>
                        <li onClick={()=>filter("conditioner")}>Conditioner</li>
                        <li onClick={()=>filter("foundation")}>Foundations</li>
                    </ul>
                    <div className={css.list} ref={parent}>
                        {
                            MenuProducts.map((product, i) => (
                                <div className={css.product} key={i}>
                                    <div className="left-s">
                                        <div className="name">
                                            <span>{product.name}</span>
                                            <span>{product.detail}</span>
                                        </div>
                                        <span>{product.price}$</span>
                                        <span>Shop Now</span>
                                    </div>
                                    <img src={product.img} alt="" className='img-p' />
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Product
