import React from 'react'
import css from './hero.module.css'
import HeroImg from '../../assets/hero.png'
import { RiShoppingBagFill } from 'react-icons/ri'
import { BsArrowRight } from 'react-icons/bs'
const Hero = () => {
    return (
        <div>
            <div className={css.container}>
                {/* left side */}
                <div className={css.h_sides}>
                    <span className={css.text1}>Skin Protection Cream</span>
                    <div className={css.text2}>
                        <span>Trendy Collection</span>
                        <span>Seedly soy has suitable disosable and boy.Excercise Joy man Children rejected</span>
                    </div>
                </div>
                {/* Middle Side */}
                <div className={css.wrapper}>
                    <div className={css.bluecircle}>
                        <img src={HeroImg} alt='' width={600} />
                        <div className={css.cart2}>
                            <RiShoppingBagFill />
                            <span className={css.signup}>Best Sign Up Offers</span>
                            <div className={css.signup}>
                                <BsArrowRight />
                            </div>
                        </div>
                    </div>
                </div>
                {/* left side */}
                <div className={css.h_sides}>
                    <div className={css.traffic}>
                        <span>10M</span>
                        <span> Monthly Traffic</span>
                    </div>
                    <div className={css.customer}>
                        <span>100k</span>
                        <span>Happy Customer</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero
